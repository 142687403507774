@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
body{
  height: 100vh;
}
#root{
  height: 100vh;
}
.App {
  text-align: center;
  height: 100%;
  background-image: url("https://www.teahub.io/photos/full/35-351549_beautiful-river-scenery-wallpaper-scenic-backgrounds-hd.jpg");
  font-family: "Roboto Condensed", sans-serif;
}
.Navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #333;
  padding: 14px 16px;
  min-height: 8vh;
  background: transparent;
}
.Navbar a {
  display: block;
  text-decoration: none;
  font-size: 21px;
  text-transform: uppercase;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
}
.Navbar a:hover {
  transform: scale(1.5);
  color: white;
}
.homepage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86vh;
}
.my-name {
  color: white;
  font-family: sans-serif;
}
.my-name h1 {
  font-size: 100px;
  margin: 0px;
}
.my-name h3 {
  font-size: 25px;
  margin: 10px;
  font-family: "Roboto Condensed", sans-serif;
}
.social {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}
.social li {
  padding: 10px;
}
.social li:hover {
  transform: scale(2);
  color: white;
}
.about-div {
  height: 86vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-about {
  height: 95%;
  width: 95%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: rgba(299, 299, 299, 0.9);
  border-radius: 50px;
}
.summary {
  grid-area: 1 / 1 / 2 / 2;
}
.about-bottom-div {
  grid-area: 2 / 1 / 3 / 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.proficient-skills {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.familiar-skills {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.want-to-learn-skills {
  grid-area: 1 / 3 / 2 / 4;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.picture-description {
  height: 69%;
  display: flex;
  width: 45vw;
  justify-content: flex-end;
  margin-left: 25vw;
}
.picture-description img {
  border-radius: 50%;
  float: left;
}
.description {
  text-align: left;
  padding-left: 10px;
  font-size: 20px;
  margin: 0;
}
.description-div {
  height: fit-content;
}
.skills-list {
  list-style: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: auto;
}
.skills-list li {
  display: flex;
}
.skills-list-div {
  margin: 15px;
  padding: 25px 0px;
  border: solid;
  box-shadow: 5px 5px 7px 5px lightgrey;
  width: 27vw;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: silver;
}
.skills-title {
  border: 0px;
  margin: 0px;
}
.colored {
  font-size: 3.5em;
  padding: 6px;
}
.resume-div {
  height: 88vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-resume {
  height: 100%;
  width: 95%;
}
.contact-div {
  height: 86vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-contact {
  height: 50%;
  width: 60%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .9fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: rgba(299, 299, 299, 0.9);
  border-radius: 50px;
}
.contact-me {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.lets-connect{
  font-size: 50px;
}
.contact-links {
  display: flex;
  justify-content: space-evenly;
}
.contact-email {
  display: flex;
}
.contact-linkedin {
  display: flex;
}
.linkedin-a {
  color: black;
  text-decoration: none;
  padding: 15px 10px;
}
.linkedin-a:hover {
  transform: scale(1.25);
  color: navy;
}
.projects-div {
  height: 92vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .description{
    font-size: large;
  }
  .MuiPaper-root.MuiCard-root{
    height: 55vh;
    width: 23vw;
  }
}
@media only screen and (min-width: 980px) and (max-width: 1023px){
  .description{
    font-size: medium;
  }
  .MuiPaper-root.MuiCard-root{
    height: 55vh;
    width: 23vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px){
  .description{
    font-size: medium;
  }
  .description-div{
    margin-bottom: 25px;
  }
  .skills-list li i {
    font-size: 2em;
  }
  .projects-div{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  #Jobra{grid-area: 1 / 1 / 2 / 2;}
  #Yourseum{grid-area: 1 / 2 / 2 / 3}
  #Steam{grid-area: 2 / 1 / 3 / 2;}
  #StudyBuddy{grid-area: 2 / 2 / 3 / 3;}
  .MuiCardContent-root{
    padding: 10px;
  }
  .MuiCardContent-root h2{
    margin: 0;
    font-size: 1rem;
  }
  .MuiCardContent-root span{
    font-size: .9rem;
  }
  .MuiPaper-root.MuiCard-root{
    height: 39vh;
    margin: 0px 70px;
  }
  .MuiCardMedia-root.makeStyles-media-2{
    height: 125px;
  }
  .MuiCardMedia-root.jss2{
    height: 125px;
  }
  /* ADD CSS FOR JSS2 AND makeStyles-media-2 */
}
@media only screen and (min-width: 480px) and (max-width: 767px){
  .picture-description{
    margin-left: 10vh;
    width: 60vw;
  }
  .description{
    font-size: small;
  }
  .description-div{
    margin-bottom: 25px;
  }
  .skills-list li i {
    font-size: 2em;
  }
  .about-bottom-div{
    width: 95vw;
  }
  .about-bottom-div h2 {
    font-size: medium;
  }
  .skills-list-div{
    margin: 5px;
  }
  .projects-div{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  #Jobra{grid-area: 1 / 1 / 2 / 2;}
  #Yourseum{grid-area: 1 / 2 / 2 / 3}
  #Steam{grid-area: 2 / 1 / 3 / 2;}
  #StudyBuddy{grid-area: 2 / 2 / 3 / 3;}
  .MuiCardContent-root{
    padding: 10px;
  }
  .MuiCardContent-root h2{
    margin: 0;
    font-size: 1rem;
  }
  .MuiCardContent-root span{
    font-size: .9rem;
  }
  .MuiPaper-root.MuiCard-root{
    height: 37vh;
    margin: 0px 15px;
    width: 215px;
  }
  .MuiCardMedia-root.makeStyles-media-2{
    height: 100px;
  }
  .MuiCardMedia-root.jss2{
    height: 100px;
  }
  .lets-connect{
    font-size: 35px;
  }
  .contact-email{
    flex-direction: column;
  }
  .contact-email svg{
    margin-left: 5vw;
  }
  .contact-linkedin{
    flex-direction: column;
  }
  .linkedin-a{
    margin-right: 5vw;
    padding: 20px 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px){
  .Navbar a{
    font-size: medium;
  }
  .inner-about {
    height: 66vh;
  }
  .picture-description{
    margin-left: 2vh;
    margin-right: 0;
    width: 70vw;
  }
  .description{
    font-size: .8em;
  }
  .description-div{
    margin-bottom: 25px;
    width: 30vw;
  }
  .skills-list li i {
    font-size: 2em;
  }
  .about-bottom-div{
    width: 95vw;
  }
  .about-bottom-div h2 {
    font-size: medium;
  }
  .skills-list-div{
    margin: 5px;
  }
  .projects-div{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  #Jobra{grid-area: 1 / 1 / 2 / 2;}
  #Yourseum{grid-area: 1 / 2 / 2 / 3}
  #Steam{grid-area: 2 / 1 / 3 / 2;}
  #StudyBuddy{grid-area: 2 / 2 / 3 / 3;}
  .MuiCardContent-root{
    padding: 10px;
  }
  .MuiCardContent-root h2{
    margin: 0;
    font-size: 1rem;
  }
  .MuiCardContent-root span{
    font-size: .8rem;
  }
  p.MuiTypography-root.MuiTypography-body2 {
    font-size: .8rem;
  }
  button.MuiButtonBase-root.MuiButton-root{
    font-size: .5rem;
    min-width: 0px;
  }
  .MuiPaper-root.MuiCard-root{
    height: 39vh;
    margin: 0px 15px;
    width: 150px;
  }
  .MuiCardMedia-root.makeStyles-media-2{
    height: 100px;
  }
  .MuiCardMedia-root.jss2{
    height: 100px;
  }
  .inner-contact{
    width: 90vw;
  }
  .lets-connect{
    font-size: 30px;
  }
  .contact-email{
    flex-direction: column;
  }
  .contact-email svg{
    margin-left: 5vw;
  }
  .contact-linkedin{
    flex-direction: column;
  }
  .linkedin-a{
    margin-right: 5vw;
    padding: 20px 0;
  }
}